import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { AuthService, EventsService, CustomerService, ResourceService } from "../../services";
import DatePicker from "react-datepicker";
import Select  from 'react-select';
import moment from 'moment-timezone';


const EventsMultipleList = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [sorting, setSorting] = useState({key: '', order: ''});
  const [selectedItems, setSelectedItems] = useState([]);
  const [resources, setResources] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date(new Date().setMonth(new Date().getMonth() + 6)));
  const [selectedResource, setSelectedResource] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState(events);
  const [showDeletedItems, setShowDeletedItems] = useState(false);


  const columns = [
    {
      key: 'customer',
      label:'Customer'

    },
    {
      key: 'chinese_name',
      label: 'Preferred Name'
    },
    {
      key: 'member_type',
      label: 'Member Type'
    },
    {
      key: 'eyes_on',
      label: 'Eyes-on'
    },
    {
      key: 'doctor',
      label: 'Doctor'

    },
    {
      key: 'phone',
      label: 'Phone'

    },
    {
      key: 'address',
      label: 'Address'

    },
    {
      key: 'translation',
      label: 'Translation'

    },
    {
      key: 'newPatient',
      label: 'New Patient',

    },
    {
      key: 'needId',
      label: 'Need ID'

    },
    {
      key: 'startTime',
      label: 'Start Time'

    },
    {
      key: 'transportation',
      label: 'Driver'
    },
    {
      key: 'fasting',
      label: 'Fasting'
    }
  ];

  const checkDisability = (customers, event) => {
    const currentCustomer = customers?.find(c => c?.id === event?.data?.customer || c?.name === event?.data?.client_name || c?.name === event?.target_name);
    return currentCustomer?.disability || event?.data?.disability?.toLowerCase() === 'yes' || false;
  };

  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    CustomerService.getAllCustomers().then((data) => {
      setCustomers(data.data);
    })
    ResourceService.getAll().then((data) => {
      setResources(data.data);
    })
  }, []);

  useEffect(() => {
    if (fromDate && toDate && customers?.length > 0 && resources?.length > 0) {
      EventsService.getAllEvents({ from: EventsService.formatDate(fromDate), to: EventsService.formatDate(toDate) }).then((data) => {
        setEvents(data.data.filter((item) => {
          item.customer = (item?.data?.customer ? (customers.find(c=>c.id === item?.data?.customer)?.name || item?.data?.client_name || '') : (item?.data?.client_name || ''));
          item.doctor = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.name || item?.data?.resource_name || '') : (item?.data?.resource_name || '');
          item.phone = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.phone || item?.data?.resource_phone || '') : (item?.data?.resource_phone || '');
          item.address = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.address || item?.data?.resource_address || '') : (item?.data?.resource_address || '');
          item.translation = item?.data?.interpreter || '';
          item.newPatient = item?.data?.new_patient || '';
          item.needId = item?.data?.need_id || '';
          item.startTime = item?.start_time? `${EventsService.formatDate(new Date(item?.start_time))} ${new Date(item?.start_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: 'numeric', hour12: true })}` : '' ;
          item.transportation = item?.link_event_name || '';
          item.confirmed = item?.confirmed || false;
          item.transMethod = item?.data?.trans_method;
          item.fasting = item?.data?.fasting || '';
          item.disability = item?.data?.disability || false;
          item.member_type = item?.data?.customer ? (customers.find(c => c.id === item?.data?.customer)?.type || '') : '';
          item.chinese_name = item?.data?.customer ? customers.find(c => c.id === item?.data?.customer)?.name_cn : (customers?.find(c=> c?.name === item?.data?.client_name || c?.name === item?.target_name )?.name_cn || '');
          item.eyes_on = checkDisability(customers, item) ? 'Yes' : 'No';
          item.dob = item?.data?.customer ? customers.find(c => c.id === item?.data?.customer)?.birth_date : (item?.data?.client_birth_date || '')
          return item;
        }).filter(item => item.type === 'medical'));
      })
    }
  }, [fromDate, toDate, customers, resources]);

  useEffect(() => {
    setFilteredEvents(events?.filter((event) => {
      if (selectedResource && selectedResource.label !== '' && selectedResource.value !== '') {
        if (selectedResource?.label?.replaceAll(' ', '').replaceAll('＊', '').replaceAll('*', '').includes(event.doctor?.replaceAll(' ', '').replaceAll('＊', '').replaceAll('*', ''))
        || event.doctor?.replaceAll(' ', '').replaceAll('＊', '').replaceAll('*', '').includes(selectedResource?.label?.replaceAll(' ', '').replaceAll('＊', '').replaceAll('*', ''))) {
          return event;
        }
      } else {
        return event;
      }
    }).filter((event => {
      if (selectedCustomer && selectedCustomer !== '' && selectedCustomer.value !== '') {
        if (selectedCustomer?.label?.replaceAll(' ', '').replaceAll('(Eyes-on)', '').replaceAll('(discharged)', '').replaceAll('＊', '').replaceAll('*', '') ===  (event.customer?.replaceAll(' ', '').replaceAll('(discharged)', '').replaceAll('(Eyes-on)', '').replaceAll('＊', '').replaceAll('*', ''))) {
          return event;
        }
      } else {
        return event;
      }
    })));
  }, [selectedResource, selectedCustomer, events]);



  useEffect(() => {
    const newEvents = [...events];
    const sortedEvents = sorting.key === '' ? newEvents : newEvents.sort((a, b) => {
      return a[sorting.key]?.localeCompare(b[sorting.key]);
    });
    setEvents(
      sorting.order === 'asc' ? sortedEvents : sortedEvents.reverse()
    )
  }, [sorting]);

  const confirmEvent = (id) => {
    EventsService.updateEvent(id, {confirmed: true}).then(() => {
      EventsService.getAllEvents({ from: EventsService.formatDate(fromDate), to: EventsService.formatDate(toDate) }).then((data) => {
        setEvents(data.data.filter((item) => {
          item.customer = (item?.data?.customer ? (customers.find(c=>c.id === item?.data?.customer)?.name || item?.data?.client_name || '') : (item?.data?.client_name || ''));
          item.doctor = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.name || item?.data?.resource_name || '') : (item?.data?.resource_name || '');
          item.phone = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.phone || item?.data?.resource_phone || '') : (item?.data?.resource_phone || '');
          item.address = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.address || item?.data?.resource_address || '') : (item?.data?.resource_address || '');
          item.translation = item?.data?.interpreter || '';
          item.newPatient = item?.data?.new_patient || '';
          item.needId = item?.data?.need_id || '';
          item.startTime = item?.start_time? `${EventsService.formatDate(new Date(item?.start_time))} ${new Date(item?.start_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: 'numeric', hour12: true })}` : '' ;
          item.transportation = item?.link_event_name || '';
          item.confirmed = item?.confirmed || false;
          item.fasting = item?.data?.fasting || '';
          item.disability = item?.data?.disability || false;
          item.transMethod = item?.data?.trans_method;
          item.eyes_on = checkDisability(customers, item) ? 'Yes' : 'No';
          item.member_type = item?.data?.customer ? (customers.find(c => c.id === item?.data?.customer)?.type || '') : '';
          item.chinese_name = item?.data?.customer ? customers.find(c => c.id === item?.data?.customer)?.name_cn : (customers?.find(c=> c?.name === item?.data?.client_name || c?.name === item?.target_name )?.name_cn || '');
          item.dob = item?.data?.customer ? customers.find(c => c.id === item?.data?.customer)?.birth_date : (item?.data?.client_birth_date || '')
          return item;
        }).filter(item => item.type === 'medical'));
      })
    });
  }


  const redirectToAdmin = () => {
    navigate(`/medical`)
  }

  const goToEdit = (id) => {
    navigate(`/medical/events/edit/${id}`)
  }

  const goToCreateNew = () => {
    navigate(`/medical/events`)
  }


  const goToView = (id) => {
    navigate(`/medical/events/${id}`)
  }

  const getSortingImg = (key) => {
    return sorting.key === key ? (sorting.order === 'asc' ? 'up_arrow' : 'down_arrow') : 'default';
  }

  const sortTableWithField = (key) => {
    let newSorting = {
      key,
      order: 'asc',
    }
    
    if (sorting.key === key && sorting.order === 'asc') {
      newSorting = {...newSorting, order: 'desc'};
    }
    setSorting(newSorting);
  }

  const toggleSelectedAllItems = () => {
    if (selectedItems.length !== filteredEvents.length || selectedItems.length === 0) {
      const newSelectedItems = [...filteredEvents].filter(ev=> ev.status === 'active').map((event) => event.id);
      setSelectedItems(newSelectedItems);
    } else {
      setSelectedItems([]);
    }
  }

  const toggleItem  = (id) => {
    if (selectedItems.includes(id)) {
      const newSelectedItems = [...selectedItems].filter((item) => item !== id);
      setSelectedItems(newSelectedItems);
    } else {
      const newSelectedItems = [...selectedItems, id];
      setSelectedItems(newSelectedItems);
    }
  }

  const checkSelectAll = () => {
    return selectedItems.length === filteredEvents.filter(ev=> ev.status === 'active').length && selectedItems.length > 0;
  }

  const generateMedicalNotificationPdf = () => {
    // const inputData = {
    //   events: []
    // };
    // selectedItems?.forEach((id) => {
    //   const event = events.find(item => item.id === id);
    //   inputData.events.push({
    //     event_time: moment(event.start_time).format('hh:mm A MM/DD/YYYY dddd'),
    //     client_name: event.data.client_name,
    //     client_status: '会员',
    //     client_dob: event.data.client_birth_date,
    //     client_pcp: event.data.client_pcp,
    //     client_pharmacy: event.data.client_pharmacy,
    //     client_seating: event.data.client_seating,
    //     doctor_name: event.data.resource_name,
    //     doctor_phone: event.data.resource_phone,
    //     doctor_contact: event.data.resource_contact,
    //     doctor_address: event.data.resource_address,
    //     title: event.title,
    //     description: event.description,
    //     interpreter: event.data.interpreter,
    //     fasting: event.data.fasting,
    //     confirmed: event.data.confirmed,
    //     new_patient: event.data.new_patient,
    //     doc_order: event.data.doc_order,
    //     need_id: event.data.need_id,
    //     need_med_list: event.data.need_med_list,
    //     reason: event.data.reason,
    //     notes: event.data.notes,
    //     other: event.data.other
    //   });
    // });
    if (selectedItems?.length <= 0) {
      window.alert("Please select at least one event record")
    } else {
      const inputData = { eventIds: selectedItems };
      EventsService.generatePdf(inputData);
    }
  }

  const generateVisitRecordSheet = async () => {
    if (selectedItems?.length <= 0) {
      window.alert("Please select at least one event record")
    } else {
      // let customer = {};
      // const inputData = {
      //   events: []
      // };
      // for (const id of selectedItems) {
      //   const event = events.find(item => item.id === id);
      //   if (!event?.data?.customer) {
      //     const legacyClient = (await CustomerService.getClient(event.target_uuid))?.data;
      //     const mappingCustomer = customers.find((item) => item.email === legacyClient.email && item.username === legacyClient.username);
      //     customer = mappingCustomer;
      //   } else {  
      //     customer = customers.find(c => c.id === event?.data?.customer);
      //   }
      //   let pharmacyInfo = 'This patient has no contracted pharmacy.\n' +
      //     'Please give the prescription directly to the patient';

      //   const pharmacy = resources.find(r => (r.id === customer?.pharmacy_id)) || resources.find(r => (r.name?.toLowerCase() === event.data.client_pharmacy?.toLowerCase()));
      //   if (pharmacy) {
      //     pharmacyInfo = `${pharmacy?.name} @ ${pharmacy?.address} \n` + 
      //     `phone:${pharmacy?.phone} fax:${pharmacy?.fax}`;
      //   } else {
      //     if (event.data.client_pharmacy) {
      //       pharmacyInfo = 'Pharmacy: ' + event.data?.client_pharmacy;
      //     }
      //   }
       
      //   const clientName = (customer?.name_cn && event?.data?.client_name && `${event?.data?.client_name} ${customer?.name_cn}`)  || customer?.name_cn || `${customer?.lastname}, ${customer?.firstname}`;
      //   inputData.events.push({
      //     event_time: moment(event.start_time).format('MM/DD/YYYY hh:mm A'),
      //     client_name: clientName, // event.data.client_name,
      //     client_status: '会员',
      //     client_dob: event.data.client_birth_date,
      //     client_pcp: event.data.client_pcp,
      //     client_pharmacy: event.data.client_pharmacy,
      //     client_seating: event.data.client_seating,
      //     doctor_name: event.data.resource_name,
      //     doctor_phone: event.data.resource_phone,
      //     doctor_contact: event.data.resource_contact,
      //     doctor_address: event.data.resource_address,
      //     title: event.title,
      //     description: event.description,
      //     interpreter: event.data.interpreter,
      //     fasting: event.data.fasting,
      //     confirmed: event.data.confirmed,
      //     new_patient: event.data.new_patient,
      //     doc_order: event.data.doc_order,
      //     need_id: event.data.need_id,
      //     need_med_list: event.data.need_med_list,
      //     reason: event.data.reason,
      //     notes: event.data.notes,
      //     other: event.data.other,
      //     pharmacy_info: pharmacyInfo
      //   })
      // }
      const inputData = { eventIds: selectedItems };
      EventsService.generateSheet(inputData);
    }
    
  };

  const generateVisitRecordPDF = async () => {
    if (selectedItems?.length <= 0) {
      window.alert("Please select at least one event record")
    } else {
      // let customer = {};
      // const inputData = {
      //   events: []
      // };
      // for (const id of selectedItems) {
      //   const event = events.find(item => item.id === id);
      //   if (!event?.data?.customer) {
      //     const legacyClient = (await CustomerService.getClient(event.target_uuid))?.data;
      //     const mappingCustomer = customers.find((item) => item.email === legacyClient.email && item.username === legacyClient.username);
      //     customer = mappingCustomer;
      //   } else {  
      //     customer = customers.find(c => c.id === event?.data?.customer);
      //   }
      //   let pharmacyInfo = 'This patient has no contracted pharmacy.\n' +
      //     'Please give the prescription directly to the patient';
      //   const pharmacy = resources.find(r => (r.id === customer?.pharmacy_id)) || resources.find(r => (r.name?.toLowerCase() === event.data.client_pharmacy?.toLowerCase()));
      //   if (pharmacy) {
      //     pharmacyInfo = `${pharmacy?.name} @ ${pharmacy?.address} \n` + 
      //     `phone:${pharmacy?.phone} fax:${pharmacy?.fax}`;
      //   } else {
      //     if (event.data.client_pharmacy) {
      //       pharmacyInfo = 'Pharmacy: ' + event.data?.client_pharmacy;
      //     }
      //   }
      //   const clientName = (customer?.name_cn && event?.data?.client_name && `${event?.data?.client_name} ${customer?.name_cn}`)  || customer?.name_cn || `${customer?.lastname}, ${customer?.firstname}`;
      //   inputData.events.push({
      //     event_time: moment(event.start_time).format('MM/DD/YYYY hh:mm A'),
      //     client_name: clientName, // event.data.client_name,
      //     client_status: '会员',
      //     client_dob: event.data.client_birth_date,
      //     client_pcp: event.data.client_pcp,
      //     client_pharmacy: event.data.client_pharmacy,
      //     client_seating: event.data.client_seating,
      //     doctor_name: event.data.resource_name,
      //     doctor_phone: event.data.resource_phone,
      //     doctor_contact: event.data.resource_contact,
      //     doctor_address: event.data.resource_address,
      //     title: event.title,
      //     description: event.description,
      //     interpreter: event.data.interpreter,
      //     fasting: event.data.fasting,
      //     confirmed: event.data.confirmed,
      //     new_patient: event.data.new_patient,
      //     doc_order: event.data.doc_order,
      //     need_id: event.data.need_id,
      //     need_med_list: event.data.need_med_list,
      //     reason: event.data.reason,
      //     notes: event.data.notes,
      //     other: event.data.other,
      //     pharmacy_info: pharmacyInfo
      //   })
      // }
      const inputData = { eventIds: selectedItems };
      EventsService.generateSheetPDF(inputData);
    }
    
  };

  const generateMedicalNotificationDocs = () => {
    if (selectedItems?.length <= 0) {
      window.alert("Please select at least one event record");
    } else {
      // const inputData = {
      //   events: [],
      //   docTemplateName: "med_notification"
      // };
      // selectedItems?.forEach((id) => {
      //   const event = events.find(item => item.id === id);
      //   inputData.events.push({
      //     event_time: moment(event.start_time).format('hh:mm A MM/DD/YYYY dddd'),
      //     client_name: event.data.client_name,
      //     client_status: '会员',
      //     client_dob: event.data.client_birth_date,
      //     client_pcp: event.data.client_pcp,
      //     client_pharmacy: event.data.client_pharmacy,
      //     client_seating: event.data.client_seating,
      //     doctor_name: event.data.resource_name,
      //     doctor_phone: event.data.resource_phone,
      //     doctor_contact: event.data.resource_contact,
      //     doctor_address: event.data.resource_address,
      //     title: event.title,
      //     description: event.description,
      //     interpreter: event.data.interpreter,
      //     fasting: event.data.fasting,
      //     confirmed: event.data.confirmed,
      //     new_patient: event.data.new_patient,
      //     doc_order: event.data.doc_order,
      //     need_id: event.data.need_id,
      //     need_med_list: event.data.need_med_list,
      //     reason: event.data.reason,
      //     notes: event.data.notes,
      //     other: event.data.other
      //   });
      // });
      const inputData = { eventIds: selectedItems };
      EventsService.generateDoc(inputData);
    }

  }
  
  return (
    <>
      <div className="list row mb-4 noprint">
        <div className="col-md-12 text-primary">
           <h5>Medical Event Multiple Days List 
             <button className="btn btn-primary btn-sm me-2 ms-2" onClick={() => {goToCreateNew()}}>Create New Medical Event</button>
             <button className="btn btn-link btn-sm me-2" onClick={() => {redirectToAdmin()}}>Back</button> 
            </h5> 
        </div>
        <div className="col-md-12 text-primary">
          <button className="btn btn-secondary btn-sm me-2" onClick={() => {generateMedicalNotificationDocs()}}>Medical Notifications Doc</button>
          <button className="btn btn-secondary btn-sm me-2" onClick={() => {generateMedicalNotificationPdf()}}>Medical Notifications Pdf</button>
          <button className="btn btn-secondary btn-sm me-2" onClick={() => {generateVisitRecordSheet()}}>Visit Record Sheet</button>
          <button className="btn btn-secondary btn-sm me-2" onClick={() => {generateVisitRecordPDF()}}>Visit Record PDF</button>
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-12 noprint">
          Pick a Date Range
        </div>
        <div className="col-md-4 col-sm-12 mb-4 noprint">
          From: <DatePicker selected={fromDate} onChange={(v) => {setFromDate(v); setSelectedItems([]);}} />
        </div>
        <div className="col-md-4 col-sm-12 mb-4 noprint">
          To: <DatePicker selected={toDate} onChange={(v) => {setToDate(v); setSelectedItems([]);}} />
        </div>
        <div className="col-md-12 noprint">
          You can Filter By:
        </div>
        <div className="col-md-4 col-sm-12 mb-4 noprint">
          Customer: <Select value={selectedCustomer || ''} onChange={selectedData => {setSelectedCustomer(selectedData); setSelectedItems([]);}} options={[{value: '', label: ''}, ...customers.map(resource => ({
            value: resource?.id || '',
            label: resource?.name || '',
          }))]}></Select>
        </div>
        <div className="col-md-4 col-sm-12 mb-4 noprint">
          Resource: <Select value={selectedResource || ''} onChange={selectedData => {setSelectedResource(selectedData); setSelectedItems([]);}} options={[{value: '', label: ''}, ...resources?.filter(r => r.status === 'active').map(resource => ({
            value: resource?.id || '',
            label: resource?.name || '',
          }))]}></Select>
        </div>
        <div className="col-md-4 col-sm-12 mb-4 noprint">
        Show Deleted Events: <input type="checkbox" value={showDeletedItems} checked={showDeletedItems === true} onClick={() => {setShowDeletedItems(!showDeletedItems); setSelectedItems([]);}} />
        </div>
        
        <div className="col-md-12">
          <table className="personnel-info-table medical"> 
            <thead>
              <tr>
                <th><input type="checkbox" checked={checkSelectAll()} onClick={() => toggleSelectedAllItems()}></input></th>
                {
                  columns.map((column, index) => <th className="sortable-header" key={index}>
                    {column.label}  <span onClick={() => sortTableWithField(column.key)}><img src={`/images/${getSortingImg(column.key)}.png`}></img></span>
                  </th>)
                }
                <th>Customer Date of Birth</th>
                <th>Transportation</th>
                <th></th>
              </tr>
              
            </thead>
            <tbody>
              {
                filteredEvents && (showDeletedItems ? filteredEvents: filteredEvents.filter(event => event.status === 'active')).map(medicalEvent => <tr key={medicalEvent.id}>
                  <td><input type="checkbox" checked={selectedItems.includes(medicalEvent.id)} onClick={()=>toggleItem(medicalEvent?.id)}/></td>
                  <td>{medicalEvent?.customer}</td>
                  <td>{medicalEvent?.chinese_name}</td>
                  <td>{medicalEvent?.member_type}</td>
                  <td>{medicalEvent?.eyes_on}</td>
                  <td>{medicalEvent?.doctor}</td>
                  <td>{medicalEvent?.phone}</td>
                  <td>{medicalEvent?.address}</td> 
                  <td>{medicalEvent?.translation}</td>
                  <td>{medicalEvent?.newPatient}</td>
                  <td>{medicalEvent?.needId}</td>
                  <td>{medicalEvent?.startTime}</td>
                  <td>{medicalEvent?.transportation}</td>
                  <td>{medicalEvent?.fasting}</td>
                  <td>{medicalEvent?.dob}</td>
                  <td>{medicalEvent?.transMethod}</td>
                  <td>{medicalEvent?.confirmed ? 'Confirmed' : <button className="btn btn-primary btn-sm me-2 ms-2" onClick={() => confirmEvent(medicalEvent?.id)}>Confirm</button>}</td>
                </tr>)
              }
            </tbody>
          </table>
           
        </div>
      </div>
    </>
  )
};

export default EventsMultipleList;