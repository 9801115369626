import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomerService, DriverService, EmployeeService, EventsService, TransRoutesService } from "../../services";
import { selectAllActiveDrivers, selectAllActiveVehicles } from "./../../store";
import moment from 'moment';
import DatePicker from "react-datepicker";
import { PERSONAL_ROUTE_STATUS, ROUTE_STATUS } from "../../shared";

const RouteSignatureList = () => {
  const params = new URLSearchParams(window.location.search);
  const drivers = useSelector(selectAllActiveDrivers);
  const vehicles = useSelector(selectAllActiveVehicles);
  
  const navigate = useNavigate();
  const [dateSelected, setDateSelected] = useState(new Date());
  const [routes, setRoutes] = useState([]);
  const [directorSignature, setDirectorSignature] = useState(undefined);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedDriver, setSelectedDriver] = useState(undefined);
  const [driverList, setDriverList] = useState([]);
  const [customers, setCustomers] = useState([]);

  const redirectToDashboard = () => {
    navigate(`/trans-routes/dashboard`);
  }

  const uploadDirectorSignature = () => {
    const formData = new FormData();
    const site = EventsService.site;
  	formData.append("file", selectedFile);
    if (selectedFile) {
      if (directorSignature) {
        CustomerService.deleteFile({'name': `center_director_signature_site_${site}`}).then(() => {
          CustomerService.uploadAvatar(`center_director_signature_site_${site}`, formData).then(() => {
            CustomerService.getAvatar(`center_director_signature_site_${site}`).then(data => {
              if (data?.data) {
                setDirectorSignature(data?.data)
              }
            });
          })
        })
      } else {
        CustomerService.uploadAvatar(`center_director_signature_site_${site}`, formData).then(() => {
          CustomerService.getAvatar(`center_director_signature_site_${site}`).then(data => {
            if (data?.data) {
              setDirectorSignature(data?.data)
            }
          });
        })
      }
    }
    
  }

  const getAllUniqueCustomers = (routes) => {
    let result = [];
    for (const route of routes) {
      const customerList = route.route_customer_list.map(item => Object.assign({}, item, {routeType: route.type, routeId: route.id, route: route, customer_status_inbound: route.type === 'inbound' && item.customer_route_status, customer_status_outbound: route.type === 'outbound' && item.customer_route_status,  inbound: route.type === 'inbound' && route, outbound: route.type === 'outbound' && route}))
      for (const customer of customerList) {
        const existItem = result.find((item => (item.customer_id === customer.customer_id) || (item?.customer_name?.replaceAll(' ', '')?.toLowerCase() === customer?.customer_name?.replaceAll(' ', '')?.toLowerCase()) ));
        if (existItem) {
          result = result.filter(item => item !== existItem);
          const newItem = Object.assign({}, existItem, {
            customer_enter_center_time: existItem?.customer_enter_center_time || customer?.customer_enter_center_time,
            customer_leave_center_time: existItem?.customer_leave_center_time || customer?.customer_leave_center_time,
            customer_pickup_time: existItem?.customer_pickup_time || customer?.customer_pickup_time,
            customer_dropoff_time: existItem?.customer_dropoff_time || customer?.customer_dropoff_time,
            inbound: existItem?.inbound || customer?.inbound,
            outbound: existItem?.outbound || customer?.outbound,
            customer_status_inbound: existItem?.customer_status_inbound || customer?.customer_status_inbound,
            customer_status_outbound: existItem?.customer_status_outbound || customer?.customer_status_outbound
          })
          result.push(newItem);
        } else {
          result.push(customer);
        }
      }
    }
    return result.sort((a, b) => {
      if (a.customer_name < b.customer_name) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  useEffect(() => {
    DriverService.getAllActiveDrivers('driver', 'active').then((data) => {
      setDriverList(data.data);
    });
    CustomerService.getAllCustomers().then((data) => setCustomers(data?.data));
  }, []);

  useEffect(() => {
    const site = EventsService.site;
    CustomerService.getAvatar(`center_director_signature_site_${site}`).then(data => {
      if (data?.data) {
        setDirectorSignature(data?.data)
      }
    });
    TransRoutesService.getAll(moment(dateSelected)?.format('MM/DD/YYYY')).then(data => {
      const routesResults = data.data;
      const finalRoutes = routesResults.map(async (routeItem) => {
        const dateArr = moment(dateSelected)?.format('MM/DD/YYYY')?.split('/') || [];
        try {
          const result = await CustomerService.getAvatar(`${routeItem.id}_${routeItem.driver}_${dateArr[0]}_${dateArr[1]}`);
          return result?.data ? Object.assign({}, routeItem, {signature: result?.data}) : routeItem;
        } catch (ex) {
          return routeItem;
        }
      });
      Promise.all(finalRoutes).then(finalRoutesData => {
        setRoutes(finalRoutesData);
      })
    })
  }, [dateSelected])


  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>View Routes Signatures <button className="btn btn-link btn-sm" onClick={() => {redirectToDashboard()}}>Back To Dashboard</button></h5> 
        </div>
      </div>
      
      <div className="list row mb-4">
				<div className="col-md-12">
					<div className="mb-4 col-md-4 col-sm-12">
						Select Date to start: <DatePicker selected={dateSelected} onChange={(v) => setDateSelected(v)} />
					</div>
          <div className="mb-4 col-md-4 col-sm-12">
						Filter By Driver: <select value={selectedDriver} onChange={e => setSelectedDriver(e.target.value)}>
                <option value={null}></option>
                {driverList.map((driver) => <option value={driver?.id}>{driver?.name}</option>)}
              </select>
					</div>
					<table className="personnel-info-table me-4">
            <thead>
              <tr>
                <th>Route Name</th>
                <th>Driver</th>
                <th>Route End Time</th>
                <th>Route Type</th>
								<th>Signature</th>
              </tr>
            </thead>
            <tbody>
							{
								routes && routes.filter((route) => {
                  if (!selectedDriver) {
                    return route;
                  } else {
                    return route?.driver === selectedDriver;
                  }
                })?.map(({id, name, end_time, driver, type, signature}, index) => {
									return (<tr key={index}>
										<td>{name}</td>
										<td>{drivers.find((d) => d.id === driver)?.name}</td>
										<td>{end_time? moment(end_time).format('HH:mm'): ''}</td>
                    <td>{type}</td>
										<td>
                      {/* {images.find((img) => img.id === id)?.image && <img width="100px" src={`data:image/jpg;base64, ${images.find((img) => img.id === id)?.image}`}/>} */}
                      {signature && <img width="100px" src={`data:image/jpg;base64, ${signature}`}/>}
                    </td>
									</tr>)
								})
							}
						</tbody>
					</table>
          <hr></hr>

          {moment(dateSelected)?.format('MM/DD/YYYY')}
          <div style={{'display': 'flex'}}>
            <div>
              <table className="personnel-info-table">
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Customer Name</th>
                    <th>Pickup Time</th>
                    <th>Enter Center Time</th>
                    <th>Leave Center Time</th>
                    <th>Drop off TIme</th>
                    <th>MA Number</th>
                    <th>Inbound Name</th>
                    <th>Outbound Name</th>
                    <th>Total Hours</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    getAllUniqueCustomers(routes?.filter((route) => {
                      if (!selectedDriver) {
                        return route;
                      } else {
                        return route?.driver === selectedDriver;
                      }
                    }))?.map(({customer_name, customer_status_inbound, customer_status_outbound, customer_id, customer_enter_center_time, customer_dropoff_time, customer_leave_center_time, customer_pickup_time, inbound, outbound}, index) => {
                      console.log('customers', customers);
                      return (<tr key={index}>
                        <td>{index+1}</td>
                        <td>{customer_name}</td>
                        <td style={{'backgroundColor': `${customer_status_inbound === PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT || customer_status_inbound === PERSONAL_ROUTE_STATUS.UNEXPECTED_ABSENT ? 'red':'white'}`}}>{customer_status_inbound !== PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT &&  customer_status_inbound !== PERSONAL_ROUTE_STATUS.UNEXPECTED_ABSENT? (customer_pickup_time ? new Date(customer_pickup_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}) : '') : ''}</td>
                        <td style={{'backgroundColor': `${customer_status_inbound === PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT || customer_status_inbound === PERSONAL_ROUTE_STATUS.UNEXPECTED_ABSENT ? 'red':'white'}`}}>{customer_status_inbound !== PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT &&  customer_status_inbound !== PERSONAL_ROUTE_STATUS.UNEXPECTED_ABSENT ? (customer_enter_center_time ? new Date(customer_enter_center_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}): ''): ''}</td>
                        <td style={{'backgroundColor': `${customer_status_outbound === PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT || customer_status_outbound === PERSONAL_ROUTE_STATUS.UNEXPECTED_ABSENT ? 'red':'white'}`}}>{customer_status_outbound !== PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT && customer_status_outbound !== PERSONAL_ROUTE_STATUS.UNEXPECTED_ABSENT ? (customer_leave_center_time ? new Date(customer_leave_center_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}): ''): ''}</td>
                        <td style={{'backgroundColor': `${customer_status_outbound === PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT || customer_status_outbound === PERSONAL_ROUTE_STATUS.UNEXPECTED_ABSENT ? 'red':'white'}`}}>{customer_status_outbound !== PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT && customer_status_outbound !== PERSONAL_ROUTE_STATUS.UNEXPECTED_ABSENT? (customer_dropoff_time ? new Date(customer_dropoff_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}) : ''): ''}</td>
                        <td>{customers.length > 0 && customers.find(c => c?.id === customer_id || c?.name === customer_name)?.medicaid_number || customers.find(c => c?.id === customer_id)?.medicare_number}</td>
                        <td>{inbound?.name || ''}</td>
                        <td>{outbound?.name || ''}</td>
                        <td>{customer_leave_center_time && customer_enter_center_time &&  Math.round((new Date(customer_leave_center_time) - new Date(customer_enter_center_time))/1000/3600) || ''}</td>
                      </tr>)
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="ms-4">
                <div className="mb-4"><strong>Center Director Signature:</strong></div>
                {directorSignature && <div className="mb-4"><img width="200px" src={`data:image/jpg;base64, ${directorSignature}`}/></div>}
                {!directorSignature && <div className="mb-4">No Director Signature Uploaded yet</div>}
                <div className="mb-4"><strong>Upload Center Director New Signature: </strong></div>
                <div className="mb-4"><input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} className="form-control-file border"></input></div>
                <div className="mb-4"><button onClick={() => uploadDirectorSignature()} className="btn btn-sm btn-primary">Submit</button></div>
            </div>
          </div>
          
				</div>
			</div>
    </>
    
  );
};

export default RouteSignatureList;