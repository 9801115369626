import React, { useState, useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAllRoutes, transRoutesSlice, vehicleSlice, selectTomorrowAllRoutes, selectAllActiveDrivers, selectAllActiveVehicles, transRouteTemplatesSlice,  selectAllActiveRouteTemplates, selectInboundRouteTemplates, selectOutboundRouteTemplates } from "./../../store";
import { Modal, Button } from "react-bootstrap";
import RouteCustomerEditor from "./RouteCustomerEditor";
import { AuthService } from "../../services";
import moment from 'moment';

const CreateRoute = () => {
  const params = new URLSearchParams(window.location.search);
  const allRoutes = useSelector(selectAllRoutes);
  const tomorrowRoutes = useSelector(selectTomorrowAllRoutes);
  const drivers = useSelector(selectAllActiveDrivers);
  const vehicles = useSelector(selectAllActiveVehicles);
  const routeTemplates = useSelector(selectAllActiveRouteTemplates);
  const inboundTemplates = useSelector(selectInboundRouteTemplates);
  const outboundTemplates = useSelector(selectOutboundRouteTemplates);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createRoute} = transRoutesSlice.actions;
  const { updateVehicle} = vehicleSlice.actions;
  const { createRouteTemplate } = transRouteTemplatesSlice.actions;
  const [routeName, setRouteName] = useState('');
  const [newDriver, setNewDriver] = useState('');
  const [newVehicle, setNewVehicle] = useState('');
  const [newRouteType, setNewRouteType] = useState(params.get('type'));
  const [showAddCheckItem, setShowAddCheckItem] = useState(false);
  const [showCopyCheckItem, setShowCopyCheckItem] = useState(false);
  const [newChecklistItems, setNewChecklistItems] = useState([]);
  const [selectedRouteChecklistToCopy, setSelectedRouteChecklistToCopy] = useState({});
  const currentVehicle = vehicles.find((vehicle) => vehicle.id === newVehicle);
  const [newCustomerList, setNewCustomerList] = useState([]);
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [useRouteTemplate, setUseRouteTemplate] = useState(undefined);
  const [currentRoute, setCurrentRoute] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if (!AuthService.canAddOrEditRoutes()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
  }, []);

  useEffect(() => {
    if (useRouteTemplate) {
      const currentTemplate = routeTemplates.find((template) => template.id === useRouteTemplate);
      setRouteName(currentTemplate.name);
      setNewDriver(currentTemplate.driver);
      setNewVehicle(currentTemplate.vehicle);
      setNewRouteType(currentTemplate.type);
      setNewCustomerList(currentTemplate.route_customer_list);
      setCurrentRoute(currentTemplate);
    } else {
      setRouteName('');
      setNewDriver('');
      setNewVehicle('');
      setNewRouteType(params.get('type'));
      setNewCustomerList([]);
    }
    setErrorMessage(undefined);
  }, [useRouteTemplate])

  const redirectToSchedule = () => {
    const date =params.get('date');
    const searchParams = date && date!=='tomorrow'? `?dateSchedule=${date}` : '';
    navigate(`/trans-routes/schedule${searchParams}`);
  }

  const redirectToDashboard = () => {
    navigate(`/trans-routes/dashboard`);
  }

  const goToTemplateList = () => {
    navigate(`/trans-routes/templates${params.get('type')?`?type=${params.get('type')}`: ''}${params.get('date')? `&date=${params.get('date')}` : ''}`);
  }

  const saveRoute = () => {
    if (!disableSave) {
      setDisableSave(true);
      if (!routeName || routeName === '') { setErrorMessage('Route Name is Required'); return;}
      if (!newRouteType || newRouteType === '') {setErrorMessage('Route Type is Required'); return;}
      if (!newDriver || newDriver === '') {setErrorMessage('Driver is Required'); return;}
      if (!newVehicle || newVehicle === '') {setErrorMessage('Vehicle is Required'); return;}
      const now = new Date();
      let scheduleDate =  ((now.getMonth() > 8) ? (now.getMonth() + 1) : ('0' + (now.getMonth() + 1))) + '/' + ((now.getDate() > 9) ? now.getDate() : ('0' + now.getDate())) + '/' + now.getFullYear();
      if (params.get('date') === 'tomorrow') {
        now.setDate(now.getDate() + 1);
        scheduleDate =  ((now.getMonth() > 8) ? (now.getMonth() + 1) : ('0' + (now.getMonth() + 1))) + '/' + ((now.getDate() > 9) ? now.getDate() : ('0' + now.getDate())) + '/' + now.getFullYear();
      } else {
        if (params.get('date')) {
          scheduleDate = moment(params.get('date')).format('MM/DD/YYYY');
        }
      }
      
      //const scheduleDate = ((now.getMonth() > 8) ? (now.getMonth() + 1) : ('0' + (now.getMonth() + 1))) + '/' + ((now.getDate() > 9) ? now.getDate() : ('0' + now.getDate())) + '/' + now.getFullYear();
      const data = Object.assign({}, {name: routeName, route_customer_list: newCustomerList, driver: newDriver, vehicle: newVehicle, type: newRouteType, schedule_date: scheduleDate, start_mileage: currentVehicle.mileage });
      const payload = {
        data
      };
      if (params.get('date') === 'tomorrow') {
        payload.fetchTomorrow = true;
      } else {
        if (params.get('date')) {
          payload.fetchDate = moment(params.get('date')).format('MM/DD/YYYY');
        }
        payload.fetchToday = true;
      }
      if (saveAsTemplate) {
        const templatePayload = Object.assign({}, {
          name: routeName, 
          route_customer_list: newCustomerList, 
          driver: newDriver, 
          vehicle: newVehicle, 
          type: newRouteType,
          status: 'active'
        });
        dispatch(createRouteTemplate({data: templatePayload}))
      }
      dispatch(createRoute(payload));
      setTimeout(() => {
        setDisableSave(false);
        if (params.get('date')) {
          redirectToSchedule();
        } else {
          redirectToDashboard();
        }
      }, 2000)
    }
    
    
  }

  const addItemToArray = () => {
    const arr = [...newChecklistItems, ''];
    setNewChecklistItems(arr);
  }

  const saveChecklistItems = () => {
    const data = Object.assign({}, currentVehicle, {checklist: newChecklistItems});
    dispatch(updateVehicle({ id: currentVehicle.id, data }));
    setShowAddCheckItem(false);
  }

  const copyChecklistItems = () => {
    const data = Object.assign({}, currentVehicle, {checklist: vehicles.find(vehicle => vehicle.id === selectedRouteChecklistToCopy.vehicle)?.checklist});
    dispatch(updateVehicle({ id: currentVehicle.id, data }));
    setShowCopyCheckItem(false);
  }

  const closeAddCheckItemModal = () => {
    setNewChecklistItems([])
    setShowAddCheckItem(false);
  }

  const showAddCheckItemModal = () => {
    setNewChecklistItems(currentVehicle.checklist)
    setShowAddCheckItem(true);
  }

  const closeCopyCheckItemModal = () => {
    setSelectedRouteChecklistToCopy({});
    setShowCopyCheckItem(false);
  }

  const showCopyCheckItemModal = () => {
    setShowCopyCheckItem(true);
  }
  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5> Create New Route <button className="btn btn-link btn-sm" onClick={() => {redirectToSchedule()}}>Back To Schedule Page</button></h5> 
        </div>
      </div>
      <div className="list row mb-5">
        <div className="col-md-7 col-sm-7 col-xs-12">
          <button className="btn btn-primary btn-sm" disabled={disableSave} onClick={() => saveRoute()}> Save </button>
          <button className="btn btn-default btn-sm" onClick={() => redirectToSchedule()}> Cancel </button>
        </div>
        {errorMessage && <div className="col-md-6 col-sm-6 col-xs-12 alert alert-danger mt-4">{errorMessage}</div>}
        
      </div>
      <div className="list row mb-4">
        <div className="col-md-6 mb-4">
          Save As Template: <input type="checkbox" value={saveAsTemplate} checked={saveAsTemplate === true} onChange={e => setSaveAsTemplate(!saveAsTemplate)}/>
        </div>
      </div>
      {((params.get('type')==="inbound" && inboundTemplates?.length>0) ||(params.get('type')==="outbound" && outboundTemplates?.length>0)) && <div className="list row mb-4">
        <div className="col-md-12 mb-4">
          Use Existed Template: <select value={useRouteTemplate} onChange={e => setUseRouteTemplate(e.currentTarget.value)}>
              <option value={''}></option>
              {
                (params.get('type') === 'inbound' && inboundTemplates?.length>0) ? inboundTemplates.map(template => <option value={template.id} key={template.id}>{template.name}</option>) : ((params.get('type') === 'outbound' && outboundTemplates?.length>0) ? outboundTemplates.map(template => <option value={template.id} key={template.id}>{template.name}</option>): <></>)
              }
            </select>
            <button className="btn btn-link btn-sm" onClick={() => goToTemplateList()}>Manage Route Templates</button>
        </div>
      </div>}
      <div className="list row mb-4">
        <div className="col-md-6 mb-4">
          Name(*): <input type="text" value={routeName || ''} onChange={e => setRouteName(e.target.value)}/>
        </div>
        <div className="col-md-6 mb-4">Vehicle(*): <select value={newVehicle} onChange={e => setNewVehicle(e.target.value)}>
            {[{id: ''}, ...vehicles].map((vehicle) => (<option key={vehicle.id} value={vehicle.id}>{vehicle.vehicle_number}</option>))}
          </select>
        </div>
        <div className="col-md-6 mb-4">Driver(*): <select value={newDriver} onChange={e => setNewDriver(e.target.value)}>
            {[{id: ''}, ...drivers].map((driver) => <option key={driver.id} value={driver.id}>{driver.name}</option>)}
          </select>
        </div>
        <div className="col-md-6 mb-4">Type(*): <select value={newRouteType} onChange={e => setNewRouteType(e.target.value)}>
            <option value=""></option>
            <option value="inbound">Inbound</option>
            <option value="outbound">Outbound</option>
          </select>
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-12 mb-4">
          <RouteCustomerEditor currentRoute={currentRoute} setNewCustomerList={setNewCustomerList}></RouteCustomerEditor>
        </div>
      </div>
      { newVehicle && newVehicle !== '' && (<div className="list row mb-4">
          <div className="col-md-12 create-route-container">
            <h6>Vehicle Info</h6>
            <div className="list row">
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Vehicle Number: {vehicles.find(item => item.id === newVehicle)?.vehicle_number}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Tag: {vehicles.find(item => item.id === newVehicle)?.tag}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">EzPass: {vehicles.find(item => item.id === newVehicle)?.ezpass}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">GPS: {vehicles.find(item => item.id === newVehicle)?.gps_tag}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Capacity: {vehicles.find(item => item.id === newVehicle)?.capacity}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Status: {vehicles.find(item => item.id === newVehicle)?.status}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Mileage: {vehicles.find(item => item.id === newVehicle)?.mileage}</div>
            </div>
          </div>
      </div>)}
      { newDriver && newDriver !== '' && (<div className="list row mb-4">
          <div className="col-md-12 create-route-container">
            <h6>Driver Info</h6>
            <div className="list row">
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Name: {drivers.find(item => item.id === newDriver)?.name}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Preferred Name: {drivers.find(item => item.id === newDriver)?.name_cn}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Driver Capacity: {drivers.find(item => item.id === newDriver)?.driver_capacity}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Roles: {drivers.find(item => item.id === newDriver)?.roles}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Phone: {drivers.find(item => item.id === newDriver)?.phone}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Email: {drivers.find(item => item.id === newDriver)?.email}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Employment Status: {drivers.find(item => item.id === newDriver)?.employment_status}</div>
            </div>
          </div>
      </div>)}
      { newVehicle && newVehicle !== '' && (<div className="list row mb-4">
        <div className="col-md-6">
          <h6>Vehicle Checklist</h6>
          { currentVehicle?.checklist?.length > 0 && (<table className="mb-4">
            <tbody>
              {currentVehicle.checklist.map((item, index) => (<tr key={index}><td>{item}</td></tr>))}
            </tbody>
          </table>) }
          <div className="mb-4"><button className="btn btn-link btn-sm" onClick={() => showAddCheckItemModal()}>+Add Check Items</button></div>
          <div className="mb-4"><button className="btn btn-link btn-sm" onClick={() => showCopyCheckItemModal()}>Copy Checklist From Other Route</button></div>
        </div>
        
      </div>)}
      <Modal show={showAddCheckItem} onHide={() => closeAddCheckItemModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Checklist Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {newChecklistItems.map((item, index) => (<div className="mb-4" key={index}><input type="text" value={item} onChange={(e) => setNewChecklistItems([...newChecklistItems].map((a, index1) => {if (index1 === index) {return e.target.value;} return a;}))}/>
            <button className="btn btn-link btn-sm" onClick={(e) => setNewChecklistItems([...newChecklistItems].filter((value, index1) => index1 != index))}>Remove</button>
            </div>))}
            <button className="btn btn-link" onClick={() => addItemToArray()}>+Add New Item</button>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeAddCheckItemModal()}>
            Close
          </Button>
          <Button variant="primary" onClick={() => saveChecklistItems()}>
            Save Checklist Items
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCopyCheckItem} onHide={() => closeCopyCheckItemModal()}>
        <Modal.Header closeButton>
          <Modal.Title> Click on Route to Select</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
           {[...allRoutes, ...tomorrowRoutes].map((route) => {
             return (<div className={`card-container ${route.id === selectedRouteChecklistToCopy.id ? 'selected': ''}`} key={route.id} onClick={() => setSelectedRouteChecklistToCopy(route)}>
               <div>{route.name}</div>
               <div>
                  {vehicles.find((a) => a.id === route.vehicle)?.checklist?.map((item, index) => <small key={index} className="me-2">{item}</small>)}
               </div>
             </div>);
           })}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeCopyCheckItemModal()}>
            Close
          </Button>
          <Button variant="primary" onClick={() => copyChecklistItems()}>
            Copy Checklist Items
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    
  );
};

export default CreateRoute;