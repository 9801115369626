import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { customerSlice } from "./../../store";
import { AuthService, CustomerService, EventsService } from "../../services";
import { CUSTOMER_TYPE } from "../../shared";
import { Spinner } from "react-bootstrap";


const CustomersList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [showInactive, setShowInactive] = useState(false);
  const [transferMap, setTransferMap] = useState({});
  // const [events, setEvents] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (!AuthService.canViewCustomers()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    CustomerService.getAllCustomers().then((data) => {
      setCustomers(data.data.sort((a, b) => a.lastname > b.lastname ? 1: -1));
    })
    // EventsService.getAllEvents({ from: EventsService.formatDate(new Date()), to: '9999-12-31'}).then((data) => {
    //   console.log('events', data.data)
    //   setEvents(data.data);
    // })
  }, []);

  const redirectToAdmin = () => {
    navigate(`/admin/customer-report`)
  }

  const goToEdit = (id) => {
    navigate(`/customers/edit/${id}`)
  }

  const goToCreateNew = () => {
    navigate(`/customers`)
  }

  const setTransferValue = (customerId, site) => {
    const currentMap = Object.assign({}, transferMap);
    if (site !== undefined && site !== null && site !== '' && site !== 0) {
      currentMap[customerId] = site;
      setTransferMap(currentMap);
    } else {
      if (customerId) {
        delete currentMap[customerId];
        setTransferMap(currentMap);
      }
    }
  }

  const goToView = (id) => {
    navigate(`/customers/${id}`)
  }
  const site = EventsService.site;

  const transferCustomer = (customerId) => {
    console.log('transfer To', transferMap[customerId]);
    if (site !== undefined && site !== null && site !== '' && site !== 0) {
      setShowSpinner(true);
      const currentCustomer = customers.find((c) => c.id === customerId);
      if (currentCustomer) {
        EventsService.getByCustomer({name: currentCustomer?.name, id: currentCustomer?.id, namecn: currentCustomer?.name_cn}).then((eventsData) => {
          const events = eventsData?.data;
          console.log('eventsToUpdate', events);
          CustomerService.updateCustomer(customerId, { ...currentCustomer, site: transferMap[customerId] }).then(() => {
            // const eventsWithCustomer = events.filter(ev => ev?.data?.customer === customerId || ev?.data?.client_name === currentCustomer?.name || ev?.target_name === currentCustomer?.name);
            if (events?.length > 0) {
              Promise.all(events?.map(
                item => EventsService.updateEvent(item?.id, {
                  ...item, site: transferMap[customerId]
                }))).then(() => {
                  CustomerService.getAllCustomers().then((data) => {
                    setCustomers(data.data?.sort((a, b) => a.lastname > b.lastname ? 1: -1));
                    setShowSpinner(false);
                  })
                setShowSpinner(false);
              }).catch((err) => setShowSpinner(false))
            } else {
              CustomerService.getAllCustomers().then((data) => {
                setCustomers(data.data?.sort((a, b) => a.lastname > b.lastname ? 1: -1));
                setShowSpinner(false);
              })
            }
            
          }).catch(err => setShowSpinner(false))
        })
      }
      
    }
  }

  const exportCSV = (customer) => {
    const csvString = [
      [...Object.keys(customer)], // Specify your headers here
      Object.keys(customer).map((key) => key && customer[key] && `"${customer[key]}"` || "") // Map your data fields accordingly
    ]
    .map(row => row.join(","))
    .join("\n");
    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: 'text/csv' });
    // Generate a download link and initiate the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `customer_${customer.name}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    EventsService.getByCustomer({name: customer?.name, id: customer?.id, namecn: customer?.name_cn}).then((data) => {
      const events = data.data;
      if (events && events?.length > 0) {
        const lastEle = events[events.length - 1]
        const eventscsvString = [
          [...Object.keys(lastEle).filter(item => item !== 'data'), ...Object.keys(lastEle?.data)],
          ...events.map((event) => {
            return [
              ...Object.keys(lastEle).filter(item => item !== 'data').map((key) => event[key] && `"${event[key]}"` || ''),
              ...Object.keys(lastEle?.data).map((key) => event?.data[key] && `"${event?.data[key]}"` || '')
            ]
          })
        ].map(row => row.join(","))
        .join("\n");
        // Create a Blob from the CSV string
        const blobCSV = new Blob([eventscsvString], { type: 'text/csv' });
        // Generate a download link and initiate the download
        const urlCSV = URL.createObjectURL(blobCSV);
        const csvlink = document.createElement('a');
        csvlink.href = urlCSV;
        csvlink.download = `Customer_${customer.name}_Medical_Events.csv`;
        document.body.appendChild(csvlink);
        csvlink.click();
        document.body.removeChild(csvlink);
        URL.revokeObjectURL(urlCSV);
      } else {
        window.alert('No medical events found for this user')
      }
      
    })
  }

  
  return (
    <>
      {showSpinner && <div className="spinner-overlay">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>}
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>All Customers <button className="btn btn-primary btn-sm" onClick={() => {goToCreateNew()}}>Create New Customer</button> <button className="btn btn-link btn-sm" onClick={() => {redirectToAdmin()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-12 mb-4">
          Filter By Name: <input className="me-2" type="text" value={keyword}  onChange={(e) => setKeyword(e.currentTarget.value)} />
        </div>
        <div className="col-md-12 mb-4">
          <input className="me-2" type="checkbox" value={showInactive} checked={showInactive === true} onChange={() => setShowInactive(!showInactive)} />
            Show Transferred / Deactivated Customers
        </div>
        <div className="col-md-12">
          <table className="personnel-info-table"> 
            <thead>
              <tr>
                <th>Name</th>
                <th>Preferred Name</th>
                <th>Email</th>
                <th>Type</th>
                <th>Pickup Status</th>
                <th>Date of Birth</th>
                <th>Gender</th>
                <th>Language</th>
                <th>Medicare Number</th>
                <th>Medicaid Number</th>
                <th>Address</th>
                <th>Phone</th>
                <th>Emergency Contact</th>
                <th></th>
                <th>Transfer To</th>
              </tr>
              
            </thead>
            <tbody>
              {
                customers && customers.filter((item) => item?.name.toLowerCase().includes(keyword.toLowerCase())).filter(item => showInactive ? item : item.type !== CUSTOMER_TYPE.TRANSFERRED && item.type!=CUSTOMER_TYPE.DECEASED && item.type!=CUSTOMER_TYPE.DISCHARED && item.status === 'active').map(customer => <tr key={customer.id}>
                  <td>{customer?.name}</td>
                  <td>{customer?.name_cn}</td>
                  <td>{customer?.email}</td>
                  <td>{customer?.type}</td>
                  <td>{customer?.pickup_status}</td>
                  <td>{customer?.birth_date}</td>
                  <td>{customer?.gender}</td>
                  <td>{customer?.language}</td>
                  <td>{customer?.medicare_number}</td>
                  <td>{customer?.medicaid_number}</td>
                  <td>{customer?.address1 || customer?.address2 || customer?.address3 || customer?.address4 || customer?.address5}</td>
                  <td>{customer?.phone || customer?.home_phone || customer?.mobile_phone}</td>
                  <td>{customer?.emergency_contact}</td>
                  <td>
                    {AuthService.canAddOrEditCustomers() && <button className="btn btn-primary btn-sm me-2" onClick={() => goToEdit(customer?.id)}>Edit</button> }
                    {AuthService.canViewCustomers() && <button className="btn btn-default btn-sm me-2" onClick={() => goToView(customer?.id)}>View</button>}
                    {AuthService.canViewCustomers() && <button className="btn btn-primary btn-sm me-2" onClick={() => exportCSV(customer)}>Export</button>}
                  </td>
                  <td>
                  {AuthService.canAddOrEditCustomers() && 
                    <div>
                      <select className="transfer-select" value={transferMap[customer?.id]} onChange={(e) => setTransferValue(customer?.id, e.target.value)}>
                        <option value=""></option>
                        { site !== 1 && <option value="1">Gaithersburg - 1</option>}
                        { site !== 2 && <option value="2">Beltsville - 2</option>}
                        { site !== 3 && <option value="3">Frederick - 3</option>}
                      </select>
                      { transferMap[customer?.id] && <button className="btn btn-primary btn-sm me-2" onClick={() => transferCustomer(customer?.id)}>Confirm</button> }
                    </div>
                   
                  }
                  </td>
                </tr>)
              }
            </tbody>
          </table>
           
        </div>
      </div>
    </>
  )
};

export default CustomersList;