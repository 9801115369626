import { takeEvery, all, call, put } from 'redux-saga/effects';
import { transRouteTemplatesSlice } from './../store';
import { RouteTemplateService } from './../services';

const {
  fetchAllRouteTemplates,
  fetchAllRouteTemplatesSuccess,
  fetchAllRouteTemplatesFailure,
  updateRouteTemplate,
  updateRouteTemplateFailure,
  createRouteTemplate,
  createRouteTemplateFailure
} = transRouteTemplatesSlice.actions;


function* fetchRouteTemplatesSaga() {
  try {
    const routes = (yield call(RouteTemplateService.getAll)).data;
    yield put(fetchAllRouteTemplatesSuccess(routes));
  } catch(ex) {
    yield put(fetchAllRouteTemplatesFailure(ex));
  }
}

function* updateRouteTemplateSaga(action) {
  try {
    yield call(RouteTemplateService.updateRouteTemplate, action.payload.id, action.payload.data);
    const routeTemplates = (yield call(RouteTemplateService.getAll)).data;
    yield put(fetchAllRouteTemplatesSuccess(routeTemplates));
    if (action.payload.callback) {
      action.payload.callack();
    }
  } catch(ex) {
    yield put(updateRouteTemplateFailure(ex));
  }
}

function* createRouteTemplateSaga(action) {
  try {
    yield call(RouteTemplateService.createNewRouteTemplate, action.payload.data);
    const routeTemplates = (yield call(RouteTemplateService.getAll)).data;
    yield put(fetchAllRouteTemplatesSuccess(routeTemplates));
    
  } catch(ex) {
    yield put(createRouteTemplateFailure(ex));
  }
}

export function* transRoutesTemplateEffect() {
  yield all([
    yield takeEvery(fetchAllRouteTemplates.type, fetchRouteTemplatesSaga),
    yield takeEvery(updateRouteTemplate.type, updateRouteTemplateSaga),
    yield takeEvery(createRouteTemplate.type, createRouteTemplateSaga)
  ])
};